import _styled2 from "styled-components";
import _styled from "styled-components";
import React from 'react';
import { motion } from 'framer-motion';
import { Box, Button as BaseButton, Text, Grid, Link } from 'system';
import { Heading } from 'src/components/Heading';
import { Button } from 'src/components/Button';
import { useSpeedBump } from 'src/hooks/useSpeedBump';
import { transition } from 'src/constants';
const MotionBox = motion.custom(Box);

var _StyledBaseButton = _styled(BaseButton).withConfig({
  displayName: "SpeedBump___StyledBaseButton",
  componentId: "okwt58-0"
})(["", ""], p => ({
  outline: 'none',
  '&:hover, &:focus': {
    '*': {
      background: p.theme.colors.gray[20] + " !important"
    }
  }
}));

const CloseButton = props => {
  return React.createElement(_StyledBaseButton, Object.assign({
    position: "absolute",
    width: ['1rem', '1.5rem', '2rem']
  }, props), React.createElement(Box, {
    height: "2px",
    bg: "white",
    mb: "4px",
    transform: "rotate(45deg) translateX(4px)",
    transitionProperty: "background"
  }), React.createElement(Box, {
    height: "2px",
    bg: "white",
    transform: "rotate(-45deg) translateX(4px)",
    transitionProperty: "background"
  }));
};

var _StyledLink = _styled(Link).withConfig({
  displayName: "SpeedBump___StyledLink",
  componentId: "okwt58-1"
})({
  textDecoration: 'underline'
});

export const SpeedBump = () => {
  const {
    isSpeedBumpVisible,
    href,
    host,
    hideSpeedBump
  } = useSpeedBump();
  return React.createElement(React.Fragment, null, React.createElement(MotionBox, {
    style: {
      pointerEvents: isSpeedBumpVisible ? 'auto' : 'none'
    },
    initial: false,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bg: "teal.40",
    zIndex: "speedBumpOverlay",
    animate: {
      opacity: isSpeedBumpVisible ? 0.95 : 0
    },
    onClick: hideSpeedBump
  }), React.createElement(MotionBox, {
    style: {
      pointerEvents: isSpeedBumpVisible ? 'auto' : 'none'
    },
    animate: {
      opacity: isSpeedBumpVisible ? 1 : 0
    },
    initial: false,
    position: "fixed",
    width: "100%",
    bottom: [0, null, 'unset'],
    top: [null, null, '50%'],
    left: [null, null, '50%'],
    zIndex: "speedBumpModal",
    pyScale: 0,
    pxScale: ['t', null, 'm'],
    transform: [null, null, 'translate(-50%, -50%)'],
    maxWidth: "large"
  }, React.createElement(MotionBox, {
    animate: {
      transform: isSpeedBumpVisible ? 'translateY(0%)' : 'translateY(100%)'
    },
    transition: transition,
    initial: false,
    position: "relative",
    bg: "teal.75",
    pyScale: ['l', null, '2xxl'],
    pxScale: ['m', null, 'xl'],
    boxShadow: "10px 10px 12px rgba(0, 0, 0, .1)"
  }, React.createElement(CloseButton, {
    topScale: "s+",
    rightScale: "s+",
    onClick: hideSpeedBump
  }), React.createElement(Text, {
    textAlign: "center",
    mbScale: "l"
  }, React.createElement(Heading, {
    as: "h2",
    color: "white",
    mbScale: "t"
  }, "You are leaving Shangri La."), React.createElement(Text, {
    as: "p",
    fontWeight: "semibold",
    fontSizeScale: "m"
  }, "Redirecting to", ' ', React.createElement(_StyledLink, {
    href: href,
    target: "_blank"
  }, host))), React.createElement(Grid, {
    gridGapScale: "s",
    gridTemplateColumns: ['1fr', 'auto auto'],
    justifyContent: "center"
  }, React.createElement(Button, {
    as: Link,
    href: href,
    target: "_blank",
    colorScheme: "white"
  }, "Continue"), React.createElement(Button, {
    colorScheme: "white",
    onClick: hideSpeedBump,
    withBorder: false,
    withHover: false
  }, "Go Back")))));
};