import React, { useContext, useState, useMemo, useCallback } from 'react';
export const NavContext = React.createContext();
export const NavProvider = ({
  children
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleisMenuOpen = useCallback(() => {
    if (!isMenuOpen) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: 0
      });
    }

    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const closeAllMenus = () => setIsMenuOpen(false);

  const memoizedValue = useMemo(() => ({
    isMenuOpen,
    toggleisMenuOpen,
    closeAllMenus
  }), [isMenuOpen, toggleisMenuOpen]);
  return React.createElement(NavContext.Provider, {
    value: memoizedValue
  }, children);
};
export const useNavContext = () => useContext(NavContext);