import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useRef, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { motion } from 'framer-motion';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import FocusTrap from 'focus-trap-react/dist/focus-trap-react';
import { SkipNavContent } from '@reach/skip-nav';
import { linearScale } from 'styled-system-scale';
import { themeGet } from '@styled-system/theme-get';
import useKey from 'react-use/lib/useKey';
import delay from 'delay';
import queryString from 'query-string';
import { getSearchQuery } from 'helpers';
import { useLocation } from 'hooks';
import { useSettingsData } from 'src/hooks/useSettingsData';
import { useSpeedBump } from 'src/hooks/useSpeedBump';
import { HEADER_HEIGHT_SCALE } from 'src/constants';
import { Box, Flex, SVG, Input, Button as ButtonBase } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { HamburgerToggle } from 'src/components/HamburgerToggle';
import { Logo } from 'src/components/Logo';
import { Button } from 'src/components/Button';
import { Anchor } from 'src/components/Anchor';
import { ReactComponent as AssetIconSearchSVG } from 'src/assets/icon-search.svg';
import { transition } from 'src/constants';
import { NavProvider, useNavContext } from './NavContext';
import { MobileNav } from './MobileNav';
import { DesktopNav } from './DesktopNav';

var _StyledBox = _styled(Box).withConfig({
  displayName: "PageBodyHeader___StyledBox",
  componentId: "sc-185bv30-0"
})(["pointer-events:none;"]);

var _StyledBox2 = _styled(Box).withConfig({
  displayName: "PageBodyHeader___StyledBox2",
  componentId: "sc-185bv30-1"
})(["pointer-events:none;"]);

var _StyledFlex = _styled(Flex).withConfig({
  displayName: "PageBodyHeader___StyledFlex",
  componentId: "sc-185bv30-2"
})(["visibility:", ";transition-delay:", ";"], p => p._css, p => p._css2);

var _StyledBox3 = _styled(Box).withConfig({
  displayName: "PageBodyHeader___StyledBox3",
  componentId: "sc-185bv30-3"
})(["visibility:", ";transform-origin:right;"], p => p._css3);

var _StyledInput = _styled(Input).withConfig({
  displayName: "PageBodyHeader___StyledInput",
  componentId: "sc-185bv30-4"
})(["outline:0;&::placeholder{color:inherit;opacity:0.5;}&:focus{border-color:", ";}"], p => p._css4);

const Header = _ref => {
  var _settingsData$tickets, _settingsData$tickets2;

  let {
    logoColor,
    color,
    bg
  } = _ref,
      props = _objectWithoutProperties(_ref, ["logoColor", "color", "bg"]);

  const location = useLocation();
  const settingsData = useSettingsData();
  const {
    toggleisMenuOpen,
    closeAllMenus,
    isMenuOpen
  } = useNavContext();
  const {
    isSpeedBumpVisible
  } = useSpeedBump();
  const headerRef = useRef();
  const searchInputRef = useRef();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  useKey('Escape', closeAllMenus);
  useEffect(() => {
    const headerNode = headerRef.current;

    const changeHeaderPosition = async () => {
      await delay(150);
      headerNode.style.position = 'fixed';
    };

    if (isMenuOpen) changeHeaderPosition();else headerNode.style.position = 'absolute';
  }, [isMenuOpen]);

  const handleHamburgerClick = () => {
    setIsSearchOpen(false);
    toggleisMenuOpen();
  };

  const handleSearchClick = event => {
    if (isSearchOpen) return;
    event.preventDefault();
    setIsSearchOpen(true);
  };

  useEffect(() => {
    var _searchInputRef$curre, _searchInputRef$curre2;

    if (isSearchOpen) searchInputRef === null || searchInputRef === void 0 ? void 0 : (_searchInputRef$curre = searchInputRef.current) === null || _searchInputRef$curre === void 0 ? void 0 : (_searchInputRef$curre2 = _searchInputRef$curre.focus) === null || _searchInputRef$curre2 === void 0 ? void 0 : _searchInputRef$curre2.call(_searchInputRef$curre);
  }, [isSearchOpen]);
  const initialQuery = getSearchQuery('query', location);

  const onSubmit = ({
    query: newQuery
  }) => {
    navigate("/search/" + (newQuery ? "?" + queryString.stringify({
      query: newQuery
    }) : ''));
  };

  return React.createElement(React.Fragment, null, React.createElement(FocusTrap, {
    active: isMenuOpen && !isSpeedBumpVisible
  }, React.createElement(Box, null, React.createElement(BoundedBox, Object.assign({
    as: "header",
    color: color,
    pyScale: "s+",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    transitionProperty: "background-color",
    zIndex: "header",
    style: {
      transitionDelay: isMenuOpen ? '0ms' : '650ms'
    },
    bg: !isMenuOpen && bg ? bg : 'transparent',
    ref: headerRef
  }, props), React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(_StyledBox, {
    bg: isMenuOpen ? 'teal.75' : 'transparent',
    display: ['none', 'block'],
    height: HEADER_HEIGHT_SCALE,
    left: 0,
    position: "absolute",
    style: {
      transitionDelay: !isMenuOpen ? '0ms' : '650ms'
    },
    top: 0,
    transitionProperty: "background-color",
    width: 1 / 2
  }), React.createElement(_StyledBox2, {
    bg: isMenuOpen ? 'teal.70' : 'transparent',
    height: HEADER_HEIGHT_SCALE,
    position: "absolute",
    right: 0,
    top: 0,
    transitionProperty: "background-color",
    width: [1, 1 / 2],
    style: {
      transitionDelay: !isMenuOpen ? '0ms' : '650ms'
    }
  }), React.createElement(Anchor, {
    href: "/",
    withExternalCheck: false
  }, React.createElement(Logo, {
    width: linearScale('10rem', '17rem', {
      count: 5
    }),
    fill: logoColor,
    mr: 4
  })), React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(_StyledFlex, {
    alignItems: "center",
    transitionProperty: "opacity, visibility",
    display: ['none', 'flex'],
    opacity: isMenuOpen ? 0 : 1,
    _css: isMenuOpen ? 'hidden' : 'visible',
    _css2: isMenuOpen ? '0ms' : '650ms'
  }, React.createElement(Formik, {
    initialValues: {
      query: initialQuery
    },
    onSubmit: onSubmit
  }, React.createElement(Flex, {
    as: FormikForm,
    position: "relative",
    mrScale: "m",
    alignItems: "center"
  }, React.createElement(_StyledBox3, {
    as: motion.div,
    initial: false,
    prScale: "s",
    mrScale: "s",
    animate: {
      scaleX: isSearchOpen ? 1 : 0,
      opacity: isSearchOpen ? 1 : 0
    },
    transition: transition,
    transitionProperty: "visibility",
    _css3: isSearchOpen ? 'visible' : 'hidden'
  }, React.createElement(_StyledInput, {
    as: FormikField,
    innerRef: searchInputRef,
    name: "query",
    placeholder: "Search",
    borderBottom: "2px solid",
    borderColor: "white",
    color: "white",
    fontSize: ['normal', null, 'medium', 'large'],
    fontWeight: "semibold",
    transitionProperty: "border-color",
    _css4: themeGet('colors.yellow.80')
  })), React.createElement(ButtonBase, {
    type: isSearchOpen ? 'submit' : 'button',
    onClick: handleSearchClick,
    width: ['1.25rem', '1.5rem', '1.75rem', '2.25rem'],
    "aria-label": "Open search field"
  }, React.createElement(SVG, {
    svg: AssetIconSearchSVG,
    x: 1,
    y: 1,
    fill: logoColor
  })))), React.createElement(Button, {
    as: Anchor,
    href: settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$tickets = settingsData.tickets_link) === null || _settingsData$tickets === void 0 ? void 0 : _settingsData$tickets.url,
    target: settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$tickets2 = settingsData.tickets_link) === null || _settingsData$tickets2 === void 0 ? void 0 : _settingsData$tickets2.target,
    colorScheme: "yellowFill",
    mrScale: "m"
  }, "Tickets")), React.createElement(HamburgerToggle, {
    color: logoColor,
    onClick: handleHamburgerClick,
    isOpen: isMenuOpen
  })))), React.createElement(DesktopNav, null), React.createElement(MobileNav, null))), React.createElement(SkipNavContent, null));
};

export const PageBodyHeader = _ref2 => {
  let {
    logoColor = 'white',
    color = 'gray.20'
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["logoColor", "color"]);

  return React.createElement(NavProvider, null, React.createElement(Header, Object.assign({
    logoColor: logoColor,
    color: color
  }, props)));
};

PageBodyHeader.mapDataToProps = ({
  nextContext
}) => ({
  logoColor: nextContext === null || nextContext === void 0 ? void 0 : nextContext.headerLogoColor,
  bg: nextContext === null || nextContext === void 0 ? void 0 : nextContext.headerBg
});