import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { navigate } from 'gatsby';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import { motion } from 'framer-motion';
import ConditionalWrap from 'conditional-wrap';
import { themeGet } from '@styled-system/theme-get';
import queryString from 'query-string';
import { getSearchQuery } from 'helpers';
import { useLocation } from 'hooks';
import { usePrimaryNavigationData } from 'src/hooks/usePrimaryNavigationData';
import { useSecondaryNavigationData } from 'src/hooks/useSecondaryNavigationData';
import { useSettingsData } from 'src/hooks/useSettingsData';
import { Box, Flex, Input, SVG } from 'system';
import { Button } from 'src/components/Button';
import { Heading } from 'src/components/Heading';
import { BoundedBox } from 'src/components/BoundedBox';
import { Anchor } from 'src/components/Anchor';
import { ReactComponent as AssetIconSearchSVG } from 'src/assets/icon-search.svg';
import { useNavContext } from './NavContext';

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "MobileNav___StyledAnchor",
  componentId: "sc-15qj8zz-0"
})(["outline:0;&:hover,&:focus{color:", ";}"], p => p._css);

const NavItemGroup = _ref => {
  let {
    heading,
    headingHref,
    headingTarget,
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["heading", "headingHref", "headingTarget", "children"]);

  return React.createElement(Box, Object.assign({
    as: motion.div,
    mbScale: "l",
    boxStyle: "lastNoMargin"
  }, props), heading && React.createElement(Heading, {
    fontSizeScale: "xl",
    lineHeight: "solid",
    mbScale: "t-",
    boxStyle: "lastNoMargin"
  }, React.createElement(ConditionalWrap, {
    condition: headingHref,
    wrap: children => React.createElement(_StyledAnchor, {
      href: headingHref,
      headingTarget: headingTarget,
      transitionProperty: "color",
      _css: themeGet('colors.yellow.80')
    }, children)
  }, heading)), React.Children.count(children) >= 1 && React.createElement(Box, {
    as: "ul"
  }, children));
};

var _StyledAnchor2 = _styled(Anchor).withConfig({
  displayName: "MobileNav___StyledAnchor2",
  componentId: "sc-15qj8zz-1"
})(["outline:none;&:hover,&:focus{color:", ";}"], p => p._css2);

const NavItem = _ref2 => {
  let {
    href,
    target,
    children
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["href", "target", "children"]);

  return React.createElement(Box, Object.assign({
    as: "li",
    mbScale: "t-",
    boxStyle: "lastNoMargin"
  }, props), React.createElement(_StyledAnchor2, {
    href: href,
    target: target,
    fontWeight: "semibold",
    color: "gray.20",
    fontSize: ['medium', null, 'large', 'xlarge'],
    transitionProperty: "color",
    _css2: themeGet('colors.white')
  }, children));
};

var _StyledAnchor3 = _styled(Anchor).withConfig({
  displayName: "MobileNav___StyledAnchor3",
  componentId: "sc-15qj8zz-2"
})(["outline:none;&:hover,&:focus{color:", ";}"], p => p._css3);

const SecondaryNavItem = _ref3 => {
  let {
    href,
    target,
    children
  } = _ref3,
      props = _objectWithoutProperties(_ref3, ["href", "target", "children"]);

  return React.createElement(Box, Object.assign({
    as: "li",
    mbScale: "t",
    boxStyle: "lastNoMargin"
  }, props), React.createElement(_StyledAnchor3, {
    href: href,
    target: target,
    fontWeight: "semibold",
    color: "gray.20",
    textStyle: "trackedCaps",
    fontSize: ['normal', null, 'medium', 'large'],
    transitionProperty: "color",
    _css3: themeGet('colors.white')
  }, children));
};

var _StyledFlex = _styled(Flex).withConfig({
  displayName: "MobileNav___StyledFlex",
  componentId: "sc-15qj8zz-3"
})(["overscroll-behavior-y:contain;-webkit-overflow-scrolling:touch;"]);

var _StyledInput = _styled(Input).withConfig({
  displayName: "MobileNav___StyledInput",
  componentId: "sc-15qj8zz-4"
})(["&::placeholder{color:inherit;opacity:0.5;}"]);

export const MobileNav = _ref4 => {
  var _settingsData$tickets, _settingsData$tickets2;

  let {
    delay = 0.2,
    withButton = true,
    withSlide = true
  } = _ref4,
      props = _objectWithoutProperties(_ref4, ["delay", "withButton", "withSlide"]);

  const location = useLocation();
  const {
    isMenuOpen,
    closeAllMenus
  } = useNavContext();
  const primaryNavigationData = usePrimaryNavigationData();
  const secondaryNavigationData = useSecondaryNavigationData();
  const settingsData = useSettingsData();
  const initialQuery = getSearchQuery('query', location);

  const onSubmit = ({
    query: newQuery
  }) => {
    closeAllMenus();
    navigate("/search/" + (newQuery ? "?" + queryString.stringify({
      query: newQuery
    }) : ''));
  };

  const variant = isMenuOpen ? 'open' : 'closed';
  const transition = {
    type: 'spring',
    damping: 30,
    stiffness: 200,
    staggerChildren: 0.1
  };
  const variants = {
    closed: {
      pointerEvents: 'none',
      translateX: withSlide ? '100vw' : 0,
      transition: _objectSpread({}, transition, {
        delay: delay + 0.2
      })
    },
    open: {
      pointerEvents: 'auto',
      translateX: 0,
      transition: _objectSpread({}, transition, {
        delayChildren: delay
      })
    }
  };
  const childVariants = {
    closed: {
      opacity: 0,
      translateX: '20px',
      transition
    },
    open: {
      opacity: 1,
      translateX: '0px',
      transition
    }
  };
  return React.createElement(BoundedBox, Object.assign({
    as: motion.nav,
    previousIsHeader: "true",
    pyScale: "0",
    display: [null, 'none'],
    height: "100%",
    color: "white",
    width: "100vw",
    bg: "teal.70",
    maxHeight: "100vh",
    position: "fixed",
    zIndex: "slidingMenu",
    "aria-hidden": isMenuOpen ? 'false' : 'true',
    "aria-label": "Main Navigation",
    initial: "closed",
    animate: variant,
    variants: variants
  }, props), React.createElement(_StyledFlex, {
    height: "100%",
    justifyContent: "center",
    overflowY: "auto",
    pbScale: "xxl"
  }, React.createElement(Box, {
    height: "min-content"
  }, React.createElement(Flex, {
    mbScale: "xl",
    alignItems: "baseline"
  }, React.createElement(Box, {
    width: 1 / 2,
    mrScale: "l"
  }, primaryNavigationData === null || primaryNavigationData === void 0 ? void 0 : primaryNavigationData.map(group => {
    var _group$primary, _group$primary2, _group$primary3, _group$primary3$link, _group$items;

    return React.createElement(NavItemGroup, {
      key: group === null || group === void 0 ? void 0 : (_group$primary = group.primary) === null || _group$primary === void 0 ? void 0 : _group$primary.name,
      heading: group === null || group === void 0 ? void 0 : (_group$primary2 = group.primary) === null || _group$primary2 === void 0 ? void 0 : _group$primary2.name,
      headingHref: group === null || group === void 0 ? void 0 : (_group$primary3 = group.primary) === null || _group$primary3 === void 0 ? void 0 : (_group$primary3$link = _group$primary3.link) === null || _group$primary3$link === void 0 ? void 0 : _group$primary3$link.url,
      boxStyle: "firstLastNoMargin",
      variants: childVariants
    }, group === null || group === void 0 ? void 0 : (_group$items = group.items) === null || _group$items === void 0 ? void 0 : _group$items.map(item => {
      var _item$link;

      return React.createElement(NavItem, {
        href: item === null || item === void 0 ? void 0 : (_item$link = item.link) === null || _item$link === void 0 ? void 0 : _item$link.url,
        key: item === null || item === void 0 ? void 0 : item.name
      }, item === null || item === void 0 ? void 0 : item.name);
    }));
  })), React.createElement(Box, {
    as: motion.ul,
    width: 1 / 2,
    variants: childVariants
  }, secondaryNavigationData === null || secondaryNavigationData === void 0 ? void 0 : secondaryNavigationData.map(item => {
    var _item$link2;

    return React.createElement(SecondaryNavItem, {
      key: item === null || item === void 0 ? void 0 : item.name,
      href: item === null || item === void 0 ? void 0 : (_item$link2 = item.link) === null || _item$link2 === void 0 ? void 0 : _item$link2.url
    }, item === null || item === void 0 ? void 0 : item.name);
  }))), React.createElement(Formik, {
    initialValues: {
      query: initialQuery
    },
    onSubmit: onSubmit
  }, React.createElement(Box, {
    as: FormikForm
  }, React.createElement(Box, {
    as: motion.div,
    position: "relative",
    variants: childVariants
  }, React.createElement(SVG, {
    svg: AssetIconSearchSVG,
    x: 1,
    y: 1,
    fill: "white",
    left: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(calc(-50% - 0.35rem))",
    width: "1.5rem"
  }), React.createElement(_StyledInput, {
    as: FormikField,
    name: "query",
    placeholder: "Search",
    borderBottom: "2px solid",
    borderColor: "white",
    color: "white",
    fontSize: "large",
    fontWeight: "semibold",
    pbScale: "t-",
    pl: 5,
    width: 1
  })))), withButton && React.createElement(Box, {
    as: motion.div,
    variants: childVariants,
    mtScale: "xl"
  }, React.createElement(Button, {
    as: Anchor,
    href: settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$tickets = settingsData.tickets_link) === null || _settingsData$tickets === void 0 ? void 0 : _settingsData$tickets.url,
    target: settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$tickets2 = settingsData.tickets_link) === null || _settingsData$tickets2 === void 0 ? void 0 : _settingsData$tickets2.target,
    colorScheme: "yellowFill",
    alignSelf: "flex-start",
    border: "none"
  }, "Tickets")))));
};