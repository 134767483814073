import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { motion } from 'framer-motion';
import { Box, Button } from 'system';
const variants = {
  closed: () => ({
    y: '0%',
    transition: {
      type: 'tween',
      ease: 'easeOut',
      when: 'afterChildren'
    }
  }),
  open: label => ({
    y: label === 'top' ? '45%' : '-45%',
    transition: {
      type: 'tween',
      ease: 'easeIn',
      when: 'beforeChildren'
    }
  })
};
const variantsInner = {
  closed: () => ({
    rotate: '0deg',
    transition: {
      type: 'tween',
      ease: 'easeIn'
    }
  }),
  open: label => ({
    rotate: label === 'top' ? '45deg' : '-45deg',
    transition: {
      type: 'tween',
      ease: 'easeOut'
    }
  })
};

var _StyledButton = _styled(Button).withConfig({
  displayName: "HamburgerToggle___StyledButton",
  componentId: "sc-1wq40ci-0"
})(["outline:none;"]);

export const HamburgerToggle = _ref => {
  let {
    isOpen,
    color = 'white'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["isOpen", "color"]);

  const variant = isOpen ? 'open' : 'closed';
  return React.createElement(_StyledButton, Object.assign({
    width: ['28px', null, null, '48px'],
    height: ['16px', null, null, '24px'],
    position: "relative"
  }, props), React.createElement(Box, {
    as: motion.div,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    custom: "top",
    initial: "closed",
    animate: variant,
    variants: variants
  }, React.createElement(Box, {
    as: motion.div,
    bg: color,
    height: "2px",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    custom: "top",
    initial: "closed",
    variants: variantsInner
  })), React.createElement(Box, {
    as: motion.div,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    custom: "bottom",
    initial: "closed",
    animate: variant,
    variants: variants
  }, React.createElement(Box, {
    as: motion.div,
    bg: color,
    height: "2px",
    bottom: 0,
    left: 0,
    right: 0,
    position: "absolute",
    initial: "closed",
    custom: "bottom",
    variants: variantsInner
  })));
};