import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getMetadata } from 'helpers';
import { propPairsEq } from 'src/helpers';
import { ARTICLE_MAX_WIDTH } from 'src/constants';
import { Text } from 'system';
import { AttributeList } from 'src/components/AttributeList';
import { BoundedBox } from 'src/components/BoundedBox';
import { Heading } from 'src/components/Heading';
export const PageBodyCareerMetadata = _ref => {
  let {
    heading,
    location,
    reportsTo,
    status
  } = _ref,
      props = _objectWithoutProperties(_ref, ["heading", "location", "reportsTo", "status"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "white",
    maxWidth: ARTICLE_MAX_WIDTH,
    pyScale: "l"
  }, props), heading && React.createElement(Heading, {
    fontSizeScale: "xl",
    mbScale: "s"
  }, heading), React.createElement(Text, {
    fontSizeScale: "b"
  }, React.createElement(AttributeList, null, location && React.createElement(AttributeList.Attribute, {
    name: "location"
  }, location), reportsTo && React.createElement(AttributeList.Attribute, {
    name: "Reports to"
  }, reportsTo), status && React.createElement(AttributeList.Attribute, {
    name: "Status"
  }, status))));
};

PageBodyCareerMetadata.mapDataToProps = ({
  meta,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _page$data, _page$data$title, _careerOpeningMetadat, _careerOpeningMetadat2, _careerOpeningMetadat3, _careerOpeningMetadat4, _careerOpeningMetadat5, _careerOpeningMetadat6;

  const rootData = meta === null || meta === void 0 ? void 0 : meta.rootData;
  const page = rootData === null || rootData === void 0 ? void 0 : rootData.prismicPage;
  const careerOpeningMetadata = getMetadata('PrismicPageMainCareerOpening', page);
  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousIsArticleTypeWithSameBackground: (previousContext === null || previousContext === void 0 ? void 0 : previousContext.isArticleType) && propPairsEq('backgroundColor', context, previousContext),
    heading: page === null || page === void 0 ? void 0 : (_page$data = page.data) === null || _page$data === void 0 ? void 0 : (_page$data$title = _page$data.title) === null || _page$data$title === void 0 ? void 0 : _page$data$title.text,
    location: careerOpeningMetadata === null || careerOpeningMetadata === void 0 ? void 0 : (_careerOpeningMetadat = careerOpeningMetadata.primary) === null || _careerOpeningMetadat === void 0 ? void 0 : (_careerOpeningMetadat2 = _careerOpeningMetadat.location) === null || _careerOpeningMetadat2 === void 0 ? void 0 : _careerOpeningMetadat2.text,
    reportsTo: careerOpeningMetadata === null || careerOpeningMetadata === void 0 ? void 0 : (_careerOpeningMetadat3 = careerOpeningMetadata.primary) === null || _careerOpeningMetadat3 === void 0 ? void 0 : (_careerOpeningMetadat4 = _careerOpeningMetadat3.reports_to) === null || _careerOpeningMetadat4 === void 0 ? void 0 : _careerOpeningMetadat4.text,
    status: careerOpeningMetadata === null || careerOpeningMetadata === void 0 ? void 0 : (_careerOpeningMetadat5 = careerOpeningMetadata.primary) === null || _careerOpeningMetadat5 === void 0 ? void 0 : (_careerOpeningMetadat6 = _careerOpeningMetadat5.status) === null || _careerOpeningMetadat6 === void 0 ? void 0 : _careerOpeningMetadat6.text
  };
};

PageBodyCareerMetadata.mapDataToContext = () => ({
  backgroundColor: 'white',
  isArticleType: true
});