import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getMetadata, getRichText } from 'helpers';
import { propPairsEq } from 'src/helpers';
import { useSettingsData } from 'src/hooks/useSettingsData';
import { ARTICLE_MAX_WIDTH } from 'src/constants';
import { Anchor } from 'src/components/Anchor';
import { AttributeList } from 'src/components/AttributeList';
import { BoundedBox } from 'src/components/BoundedBox';
import { Button } from 'src/components/Button';
import { HTMLContent } from 'src/components/HTMLContent';
export const PageBodyCareerApplyButton = _ref => {
  let {
    applyHref
  } = _ref,
      props = _objectWithoutProperties(_ref, ["applyHref"]);

  const settingsData = useSettingsData();
  const footnoteHTML = getRichText(settingsData === null || settingsData === void 0 ? void 0 : settingsData.career_openings_footnote);
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "white",
    maxWidth: ARTICLE_MAX_WIDTH
  }, props), React.createElement(Button, {
    as: Anchor,
    href: applyHref,
    target: "_blank",
    mbScale: "s"
  }, "Apply Now"), React.createElement(HTMLContent, {
    html: footnoteHTML,
    fontSize: "t",
    maxWidth: "30rem"
  }));
};
PageBodyCareerApplyButton.Item = AttributeList.Attribute;

PageBodyCareerApplyButton.mapDataToProps = ({
  meta,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _careerOpeningMetadat, _careerOpeningMetadat2;

  const rootData = meta === null || meta === void 0 ? void 0 : meta.rootData;
  const page = rootData === null || rootData === void 0 ? void 0 : rootData.prismicPage;
  const careerOpeningMetadata = getMetadata('PrismicPageMainCareerOpening', page);
  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    applyHref: careerOpeningMetadata === null || careerOpeningMetadata === void 0 ? void 0 : (_careerOpeningMetadat = careerOpeningMetadata.primary) === null || _careerOpeningMetadat === void 0 ? void 0 : (_careerOpeningMetadat2 = _careerOpeningMetadat.apply_link) === null || _careerOpeningMetadat2 === void 0 ? void 0 : _careerOpeningMetadat2.url
  };
};

PageBodyCareerApplyButton.mapDataToContext = () => ({
  backgroundColor: 'white'
});