import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { notEmpty } from 'helpers';
import { camelCase } from 'tiny-compose-fns';
import ConditionalWrap from 'conditional-wrap';
import { propPairsEq } from 'src/helpers';
import { ARTICLE_MAX_WIDTH } from 'src/constants';
import { Flex, Image } from 'system';
import { Anchor } from 'src/components/Anchor';
import { BoundedBox } from 'src/components/BoundedBox';
const colorSchemes = {
  white: {
    bgColor: 'white'
  },
  lightGray: {
    bgColor: 'gray.90'
  }
};
export const PageBodyLogos = _ref => {
  let {
    children,
    backgroundColor = 'white'
  } = _ref,
      props = _objectWithoutProperties(_ref, ["children", "backgroundColor"]);

  const colors = colorSchemes[backgroundColor];
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    maxWidth: ARTICLE_MAX_WIDTH,
    bg: colors.bgColor
  }, props), React.createElement(Flex, {
    flexWrap: "wrap",
    mrScale: "-l",
    mbScale: "-l",
    alignItems: "center",
    justifyContent: "center"
  }, children));
};

PageBodyLogos.Logo = _ref2 => {
  let {
    imageFluid,
    imageURL,
    imageAlt,
    href,
    target
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["imageFluid", "imageURL", "imageAlt", "href", "target"]);

  return React.createElement(Flex, Object.assign({
    width: 1,
    maxWidth: ['11rem', null, '15rem'],
    prScale: "l",
    pbScale: "l",
    alignItems: "center",
    justifyContent: "center"
  }, props), React.createElement(ConditionalWrap, {
    wrap: children => React.createElement(Flex, {
      as: Anchor,
      alignItems: "center",
      justifyContent: "center",
      href: href,
      target: target,
      width: 1
    }, children),
    condition: href
  }, React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt,
    width: 1
  })));
};

PageBodyLogos.mapDataToProps = ({
  data,
  previousType,
  context,
  previousContext,
  nextContext
}) => {
  var _data$primary, _data$items;

  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
    nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
    nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
    previousIsArticleTypeWithSameBackground: (previousContext === null || previousContext === void 0 ? void 0 : previousContext.isArticleType) && propPairsEq('backgroundColor', context, previousContext),
    backgroundColor: camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.background_color),
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : _data$items.map(item => {
      var _item$image, _item$image2, _item$image3, _item$link, _item$link2;

      return React.createElement(PageBodyLogos.Logo, {
        imageFluid: item === null || item === void 0 ? void 0 : (_item$image = item.image) === null || _item$image === void 0 ? void 0 : _item$image.fluid,
        imageURL: item === null || item === void 0 ? void 0 : (_item$image2 = item.image) === null || _item$image2 === void 0 ? void 0 : _item$image2.url,
        imageAlt: notEmpty(item === null || item === void 0 ? void 0 : (_item$image3 = item.image) === null || _item$image3 === void 0 ? void 0 : _item$image3.alt),
        href: item === null || item === void 0 ? void 0 : (_item$link = item.link) === null || _item$link === void 0 ? void 0 : _item$link.url,
        target: item === null || item === void 0 ? void 0 : (_item$link2 = item.link) === null || _item$link2 === void 0 ? void 0 : _item$link2.target
      });
    })
  };
};

PageBodyLogos.mapDataToContext = ({
  data
}) => {
  var _data$primary2;

  const colorScheme = camelCase(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.background_color);
  const colors = colorSchemes[colorScheme];
  return {
    backgroundColor: colors.bgColor,
    isArticleType: true
  };
};

export const fragment = "2811820798";