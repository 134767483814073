import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useRef } from 'react';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import VisuallyHidden from '@reach/visually-hidden';
import { themeGet } from '@styled-system/theme-get';
import { linearScale } from 'styled-system-scale';
import { noop } from 'tiny-compose-fns';
import { propPairsEq } from 'src/helpers';
import { Box, Flex, Input, SVG, Button } from 'system';
import { Subheading } from 'src/components/Subheading';
import { BoundedBox } from 'src/components/BoundedBox';
import { ReactComponent as AssetIconSearchSVG } from 'src/assets/icon-search.svg';

var _StyledInput = _styled(Input).withConfig({
  displayName: "PageBodySearchBar___StyledInput",
  componentId: "y9no70-0"
})(["outline:0;&::placeholder{color:", ";opacity:0.25;}&:focus{border-color:", ";}"], p => p._css, p => p._css2);

var _StyledButton = _styled(Button).withConfig({
  displayName: "PageBodySearchBar___StyledButton",
  componentId: "y9no70-1"
})(["outline:0;&:hover,&:focus{color:", ";}"], p => p._css3);

export const PageBodySearchBar = _ref => {
  let {
    initialQuery = '',
    onSubmit: passedOnSubmit = noop
  } = _ref,
      props = _objectWithoutProperties(_ref, ["initialQuery", "onSubmit"]);

  const inputRef = useRef();

  const onSubmit = async (values, {
    setSubmitting
  }) => {
    var _inputRef$current;

    (_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 ? void 0 : _inputRef$current.blur();
    await Promise.resolve(passedOnSubmit(values));
    setSubmitting(false);
  };

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "gray.90",
    pyScale: "xl"
  }, props), React.createElement(Formik, {
    initialValues: {
      query: initialQuery
    },
    onSubmit: onSubmit
  }, ({
    isSubmitting
  }) => React.createElement(Box, {
    as: FormikForm
  }, React.createElement(Subheading, {
    as: "h2",
    color: "teal.40",
    mbScale: "t"
  }, "Search results for"), React.createElement(Flex, {
    width: [5 / 6, 3 / 6, 2 / 6]
  }, React.createElement(_StyledInput, {
    as: FormikField,
    innerRef: inputRef,
    name: "query",
    placeholder: "Search\u2026",
    borderBottom: "2px solid",
    borderColor: "gray.20",
    flex: "1 1 0px",
    fontSizeScale: "xl",
    fontWeight: "semibold",
    mrScale: "t",
    pbScale: "t-",
    transitionProperty: "border-color",
    width: 1,
    _css: themeGet('colors.teal.40'),
    _css2: themeGet('colors.teal.70')
  }), React.createElement(_StyledButton, {
    type: "submit",
    transitionProperty: "color",
    disabled: isSubmitting,
    _css3: themeGet('colors.teal.70')
  }, React.createElement(VisuallyHidden, null, "Search"), React.createElement(SVG, {
    svg: AssetIconSearchSVG,
    x: 1,
    y: 1,
    width: linearScale('1.75rem', '3rem', {
      count: 5
    }),
    fill: "currentColor"
  }))))));
};

PageBodySearchBar.mapDataToProps = ({
  data,
  previousType,
  context,
  previousContext,
  nextContext
}) => ({
  previousIsHeader: previousType === 'PageBodyHeader',
  previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
  nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
  nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
  onSubmit: data === null || data === void 0 ? void 0 : data.onSubmit,
  initialQuery: data === null || data === void 0 ? void 0 : data.initialQuery
});

PageBodySearchBar.mapDataToContext = () => ({
  backgroundColor: 'gray.90',
  headerLogoColor: 'white',
  headerBg: 'teal.75'
});