import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
} // 1. Import your slice


import { PageBodyCallToAction } from './PageBodyCallToAction';
import { PageBodyCareerApplyButton } from './PageBodyCareerApplyButton';
import { PageBodyCareerMetadata } from './PageBodyCareerMetadata';
import { PageBodyCareerOpenings } from './PageBodyCareerOpenings';
import { PageBodyContactInfo } from './PageBodyContactInfo';
import { PageBodyDocumentsList } from './PageBodyDocumentsList';
import { PageBodyDottedPathCta } from './PageBodyDottedPathCta';
import { PageBodyExperience } from './PageBodyExperience';
import { PageBodyExperienceCards } from './PageBodyExperienceCards';
import { PageBodyFeaturedExperience } from './PageBodyFeaturedExperience';
import { PageBodyFeaturedExperiences } from './PageBodyFeaturedExperiences';
import { PageBodyFeaturedObjects } from './PageBodyFeaturedObjects';
import { PageBodyFilterableExperiences } from './PageBodyFilterableExperiences';
import { PageBodyFooter } from './PageBodyFooter';
import { PageBodyForm } from './PageBodyForm';
import { PageBodyGroupedExperiences } from './PageBodyGroupedExperiences';
import { PageBodyHeader } from './PageBodyHeader';
import { PageBodyHeroImage } from './PageBodyHeroImage';
import { PageBodyImageCallout } from './PageBodyImageCallout';
import { PageBodyImages } from './PageBodyImages';
import { PageBodyInfoCards } from './PageBodyInfoCards';
import { PageBodyLargeDottedPathCta } from './PageBodyLargeDottedPathCta';
import { PageBodyLargeText } from './PageBodyLargeText';
import { PageBodyLargeTextOnImage } from './PageBodyLargeTextOnImage';
import { PageBodyLatestNewsCards } from './PageBodyLatestNewsCards';
import { PageBodyLogos } from './PageBodyLogos';
import { PageBodyNewsPostHeader } from './PageBodyNewsPostHeader';
import { PageBodyNote } from './PageBodyNote';
import { PageBodyPageHeader } from './PageBodyPageHeader';
import { PageBodyPastExperiencesList } from './PageBodyPastExperiencesList';
import { PageBodyPullQuote } from './PageBodyPullQuote';
import { PageBodySearchBar } from './PageBodySearchBar';
import { PageBodySearchResults } from './PageBodySearchResults';
import { PageBodySectionHeader } from './PageBodySectionHeader';
import { PageBodySmallExperienceCards } from './PageBodySmallExperienceCards';
import { PageBodyStaffMembers } from './PageBodyStaffMembers';
import { PageBodyText } from './PageBodyText';
import { PageBodyTextWithImage } from './PageBodyTextWithImage';
import { PageBodyAnchor } from './PageBodyAnchor'; // 2. Add your slice

export const slicesMap = {
  PageBodyAnchor,
  PageBodyCallToAction,
  PageBodyCareerApplyButton,
  PageBodyCareerMetadata,
  PageBodyCareerOpenings,
  PageBodyContactInfo,
  PageBodyDocumentsList,
  PageBodyDottedPathCta,
  PageBodyExperience,
  PageBodyExperienceCards,
  PageBodyFeaturedExperience,
  PageBodyFeaturedExperiences,
  PageBodyFeaturedObjects,
  PageBodyFilterableExperiences,
  PageBodyFooter,
  PageBodyForm,
  PageBodyGroupedExperiences,
  PageBodyHeader,
  PageBodyHeroImage,
  PageBodyImageCallout,
  PageBodyImages,
  PageBodyInfoCards,
  PageBodyLargeDottedPathCta,
  PageBodyLargeText,
  PageBodyLargeTextOnImage,
  PageBodyLatestNewsCards,
  PageBodyLogos,
  PageBodyNewsPostHeader,
  PageBodyNote,
  PageBodyPageHeader,
  PageBodyPastExperiencesList,
  PageBodyPullQuote,
  PageBodySearchBar,
  PageBodySearchResults,
  PageBodySectionHeader,
  PageBodySmallExperienceCards,
  PageBodyStaffMembers,
  PageBodyText,
  PageBodyTextWithImage
}; // 3. Add your slice fragment

export const fragment = "2314400093";
export const mapDataToPropsEnhancer = (props, {
  previousData
}) => {
  var _previousData$primary;

  return _objectSpread({
    id: previousData === null || previousData === void 0 ? void 0 : (_previousData$primary = previousData.primary) === null || _previousData$primary === void 0 ? void 0 : _previousData$primary.anchor_link
  }, props);
};