import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import staticQueryData from "../../public/static/d/3565467236.json";
import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { getRichText } from 'helpers';
import { castArray } from 'tiny-compose-fns';
import { useSecondaryNavigationData } from 'src/hooks/useSecondaryNavigationData';
import { useTertiaryNavigationData } from 'src/hooks/useTertiaryNavigationData';
import { Box, Flex, Image, SVG, Grid, Text } from 'system';
import { Logo } from 'src/components/Logo';
import { BoundedBox } from 'src/components/BoundedBox';
import { HTMLContent } from 'src/components/HTMLContent';
import { Subheading } from 'src/components/Subheading';
import { Anchor } from 'src/components/Anchor';
import { ReactComponent as AssetFBIconSVG } from 'src/assets/icon-facebook.svg';
import { ReactComponent as AssetInstaIconSVG } from 'src/assets/icon-instagram.svg';
import { ReactComponent as AssetTwitterIconSVG } from 'src/assets/icon-twitter.svg';
import { ReactComponent as AssetDDFLogoSVG } from 'src/assets/ddf-logo.svg';
import AssetMediaFlake from 'src/assets/media-flake.png';

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "PageBodyFooter___StyledAnchor",
  componentId: "dgh4pu-0"
})(["outline:0;&:hover,&:focus{color:", ";}"], p => p._css);

const SocialIcon = ({
  svg,
  href,
  hrefTarget
}) => React.createElement(_StyledAnchor, {
  href: href,
  target: hrefTarget,
  mrScale: "t",
  color: "teal.40",
  _css: themeGet('colors.teal.70')
}, React.createElement(SVG, {
  x: 1,
  y: 1,
  width: ['1.5rem', null, null, '1.75rem'],
  svg: svg,
  boxStyle: "lastNoMargin",
  color: "inherit",
  transitionProperty: "color"
}));

var _StyledAnchor2 = _styled(Anchor).withConfig({
  displayName: "PageBodyFooter___StyledAnchor2",
  componentId: "dgh4pu-1"
})(["white-space:nowrap;&:hover,&:focus{color:", ";}"], p => p._css2);

const NavItem = _ref => {
  let {
    href,
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, ["href", "children"]);

  return React.createElement(Box, Object.assign({
    as: "li",
    mbScale: "t",
    boxStyle: "lastNoMargin"
  }, props), React.createElement(_StyledAnchor2, {
    href: href,
    fontWeight: "semibold",
    fontSizeScale: "m",
    lineHeight: "title",
    display: "inline-block",
    transitionProperty: "color",
    _css2: themeGet('colors.teal.70')
  }, children));
};

var _StyledAnchor3 = _styled(Anchor).withConfig({
  displayName: "PageBodyFooter___StyledAnchor3",
  componentId: "dgh4pu-2"
})(["&:hover,&:focus{color:", ";}"], p => p._css3);

const NavItemGroup = _ref2 => {
  let {
    heading,
    headingHref,
    headingTarget,
    children
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["heading", "headingHref", "headingTarget", "children"]);

  return React.createElement(Box, Object.assign({
    boxStyle: "firstLastNoMargin"
  }, props), React.createElement(Subheading, {
    color: "orange.60",
    mbScale: "t"
  }, React.createElement(_StyledAnchor3, {
    href: headingHref,
    headingTarget: headingTarget,
    transitionProperty: "color",
    _css3: themeGet('colors.teal.70')
  }, heading)), React.createElement(Box, {
    as: "ul"
  }, children));
};

var _StyledImage = _styled(Image).withConfig({
  displayName: "PageBodyFooter___StyledImage",
  componentId: "dgh4pu-3"
})(["pointer-events:none;"]);

var _StyledBox = _styled(Box).withConfig({
  displayName: "PageBodyFooter___StyledBox",
  componentId: "dgh4pu-4"
})(["pointer-events:none;"]);

var _StyledAnchor4 = _styled(Anchor).withConfig({
  displayName: "PageBodyFooter___StyledAnchor4",
  componentId: "dgh4pu-5"
})(["white-space:nowrap;&:hover,&:focus{color:", ";}"], p => p._css4);

var _StyledAnchor5 = _styled(Anchor).withConfig({
  displayName: "PageBodyFooter___StyledAnchor5",
  componentId: "dgh4pu-6"
})(["&:hover,&:focus{color:", ";}"], p => p._css5);

var _StyledGrid = _styled(Grid).withConfig({
  displayName: "PageBodyFooter___StyledGrid",
  componentId: "dgh4pu-7"
})(["justify-content:start;"]);

var _StyledAnchor6 = _styled(Anchor).withConfig({
  displayName: "PageBodyFooter___StyledAnchor6",
  componentId: "dgh4pu-8"
})(["outline:none;&:hover,&:focus{color:", ";}"], p => p._css6);

export const PageBodyFooter = props => {
  var _queryData$prismicSet, _queryData$prismicSet2, _queryData$prismicSet3, _queryData$prismicSet4, _queryData$prismicNav, _queryData$prismicNav2, _queryData$prismicSet5, _queryData$prismicSet6, _queryData$prismicSet7, _queryData$prismicSet8, _queryData$prismicSet9, _queryData$prismicSet10;

  const queryData = staticQueryData.data;
  const secondaryNavData = useSecondaryNavigationData();
  const tertiaryNavData = useTertiaryNavigationData();
  const {
    previousIsGrayBackground
  } = props;
  const address = getRichText(queryData === null || queryData === void 0 ? void 0 : (_queryData$prismicSet = queryData.prismicSettings) === null || _queryData$prismicSet === void 0 ? void 0 : (_queryData$prismicSet2 = _queryData$prismicSet.data) === null || _queryData$prismicSet2 === void 0 ? void 0 : _queryData$prismicSet2.address);
  const copyright = getRichText(queryData === null || queryData === void 0 ? void 0 : (_queryData$prismicSet3 = queryData.prismicSettings) === null || _queryData$prismicSet3 === void 0 ? void 0 : (_queryData$prismicSet4 = _queryData$prismicSet3.data) === null || _queryData$prismicSet4 === void 0 ? void 0 : _queryData$prismicSet4.copyright);
  const navGroups = queryData === null || queryData === void 0 ? void 0 : (_queryData$prismicNav = queryData.prismicNavigation) === null || _queryData$prismicNav === void 0 ? void 0 : (_queryData$prismicNav2 = _queryData$prismicNav.data) === null || _queryData$prismicNav2 === void 0 ? void 0 : _queryData$prismicNav2.primary;
  return React.createElement(BoundedBox, Object.assign({
    as: "footer",
    bg: "gray.90",
    color: "gray.20",
    overflow: "hidden",
    position: "relative",
    pyScale: ['xl', 'l']
  }, props), React.createElement(Box, {
    display: previousIsGrayBackground ? 'block' : 'none',
    bg: "gray.95",
    height: "2px",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0
  }), React.createElement(Box, {
    position: "relative"
  }, React.createElement(_StyledImage, {
    src: AssetMediaFlake,
    height: "auto",
    right: [0, null, null, 'auto'],
    left: [null, null, null, 0],
    opacity: [0.4, null, null, 0.1],
    position: "absolute",
    top: "50%",
    transform: ['translate(38%, -75%)', null, null, 'translate(-40%, -40%)'],
    ml: [null, null, null, 'calc(-1 * (100vw - 100%) / 2)'],
    width: ['22rem', '28rem'],
    zIndex: ['footerFlakeTransparent', null, null, 'footerFlakeTeal']
  }), React.createElement(Grid, {
    position: "relative",
    gridTemplateColumns: ['1fr 1fr', '.5fr 1fr', 'repeat(5, min-content)', '24% repeat(5, 1fr)', '33% repeat(5, 1fr)'],
    gridColumnGapScale: [0, null, 'xl', 'l', 'xl'],
    zIndex: "footerContent"
  }, React.createElement(Box, {
    gridColumn: ['1 / -1', null, null, 1],
    gridRow: [null, null, null, 'span 3'],
    mbScale: ['xl', null, null, 0],
    position: "relative"
  }, React.createElement(Logo, {
    as: Anchor,
    href: "/",
    width: ['12.5rem', null, '15rem', '20rem', '22rem'],
    fill: ['gray.20', null, null, 'white']
  }), React.createElement(_StyledBox, {
    position: "absolute",
    display: ['none', null, null, 'block'],
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    bg: "teal.70",
    zIndex: "footerTealBox",
    ml: "-50vw",
    myScale: "-l"
  })), navGroups === null || navGroups === void 0 ? void 0 : navGroups.map(group => {
    var _group$primary, _group$primary2, _group$primary2$link, _group$primary3, _group$items;

    return React.createElement(NavItemGroup, {
      heading: group === null || group === void 0 ? void 0 : (_group$primary = group.primary) === null || _group$primary === void 0 ? void 0 : _group$primary.name,
      headingHref: group === null || group === void 0 ? void 0 : (_group$primary2 = group.primary) === null || _group$primary2 === void 0 ? void 0 : (_group$primary2$link = _group$primary2.link) === null || _group$primary2$link === void 0 ? void 0 : _group$primary2$link.url,
      key: group === null || group === void 0 ? void 0 : (_group$primary3 = group.primary) === null || _group$primary3 === void 0 ? void 0 : _group$primary3.name,
      mbScale: ['m', null, 0]
    }, group === null || group === void 0 ? void 0 : (_group$items = group.items) === null || _group$items === void 0 ? void 0 : _group$items.map(item => {
      var _item$link;

      return React.createElement(NavItem, {
        href: item === null || item === void 0 ? void 0 : (_item$link = item.link) === null || _item$link === void 0 ? void 0 : _item$link.url,
        key: item === null || item === void 0 ? void 0 : item.name
      }, item === null || item === void 0 ? void 0 : item.name);
    }));
  }), React.createElement(Box, {
    as: "ul",
    display: ['none', null, 'block'],
    gridColumn: [4, null, null, 5],
    mbScale: "t"
  }, secondaryNavData.map(item => React.createElement(Box, {
    as: "li",
    key: item.name,
    boxStyle: "lastNoMargin",
    mbScale: "t-"
  }, React.createElement(Subheading, {
    as: "span",
    color: "teal.40"
  }, React.createElement(_StyledAnchor4, {
    href: item.link.url,
    target: item.link.target,
    color: "inherit",
    transitionProperty: "color",
    _css4: themeGet('colors.teal.70')
  }, item.name))))), React.createElement(Flex, {
    mbScale: ['s', null, 0],
    gridColumn: [null, null, 4, 5],
    gridRow: [null, null, null, 2]
  }, React.createElement(SocialIcon, {
    svg: AssetFBIconSVG,
    href: "https://facebook.com/" + (queryData === null || queryData === void 0 ? void 0 : (_queryData$prismicSet5 = queryData.prismicSettings) === null || _queryData$prismicSet5 === void 0 ? void 0 : (_queryData$prismicSet6 = _queryData$prismicSet5.data) === null || _queryData$prismicSet6 === void 0 ? void 0 : _queryData$prismicSet6.facebook_handle),
    hrefTarget: "_blank"
  }), React.createElement(SocialIcon, {
    svg: AssetInstaIconSVG,
    href: "https://instagram.com/" + (queryData === null || queryData === void 0 ? void 0 : (_queryData$prismicSet7 = queryData.prismicSettings) === null || _queryData$prismicSet7 === void 0 ? void 0 : (_queryData$prismicSet8 = _queryData$prismicSet7.data) === null || _queryData$prismicSet8 === void 0 ? void 0 : _queryData$prismicSet8.instagram_handle),
    hrefTarget: "_blank"
  }), React.createElement(SocialIcon, {
    svg: AssetTwitterIconSVG,
    href: "https://twitter.com/" + (queryData === null || queryData === void 0 ? void 0 : (_queryData$prismicSet9 = queryData.prismicSettings) === null || _queryData$prismicSet9 === void 0 ? void 0 : (_queryData$prismicSet10 = _queryData$prismicSet9.data) === null || _queryData$prismicSet10 === void 0 ? void 0 : _queryData$prismicSet10.instagram_handle) + "?lang=en",
    hrefTarget: "_blank"
  })), React.createElement(HTMLContent, {
    html: address,
    lineHeight: "wide",
    gridColumn: ['1 / -1', null, 5, 6],
    gridRow: [null, null, 2, 1],
    mtScale: ['l', null, 0],
    mbScale: "t",
    map: {
      a: props => React.createElement(_StyledAnchor5, Object.assign({
        transitionProperty: "color",
        color: "gray.20"
      }, props, {
        _css5: themeGet('colors.teal.70')
      }))
    }
  }), React.createElement(_StyledGrid, {
    gridColumn: ['1 / -1', null, null, '2 / -1'],
    gridRow: [null, null, 4, 3],
    mtScale: "m",
    alignItems: "baseline",
    gridTemplateColumns: ['auto 1fr', null, 'repeat(3, auto)'],
    gridColumnGapScale: "s",
    gridRowGapScale: "t"
  }, React.createElement(Flex, {
    gridColumn: "1 / -1",
    flexDirection: ['column', 'row'],
    alignItems: ['flex-start', 'flex-end'],
    mbScale: "s"
  }, React.createElement(Anchor, {
    href: "https://dorisduke.org",
    maxWidth: "10rem",
    width: "100%"
  }, React.createElement(SVG, {
    x: 521,
    y: 129,
    svg: AssetDDFLogoSVG
  })), React.createElement(Text, {
    as: "p",
    mtScale: ['t', 0],
    mlScale: [0, 's'],
    fontSizeScale: ['b', null, 's+']
  }, "Shangri La is a center of the Doris Duke Foundation.")), tertiaryNavData.map(item => React.createElement(_StyledAnchor6, {
    key: item.name,
    display: "block",
    href: item.link.url,
    target: item.link.target,
    transitionProperty: "color",
    _css6: themeGet('colors.teal.70')
  }, item.name)), React.createElement(HTMLContent, {
    gridColumn: ['1 / -1', null, 'auto'],
    as: "small",
    html: copyright,
    fontSizeScale: "s",
    color: "gray.60"
  })))));
};

PageBodyFooter.mapDataToProps = ({
  previousContext
}) => ({
  previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
  previousIsGrayBackground: castArray(previousContext === null || previousContext === void 0 ? void 0 : previousContext.backgroundColor).includes('gray.90')
});

const query = "3565467236";