import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { negateScale } from 'styled-system-scale';
import { Flex, Text, AspectRatio, Box, ImageContainer, Image } from 'system';
import { notEmpty, getMetadata } from 'helpers';
import { BoundedBox } from 'src/components/BoundedBox';
import { Heading } from 'src/components/Heading';
import { BackButton } from 'src/components/BackButton';
import { theme } from 'src/theme';
export const PageBodyNewsPostHeader = _ref => {
  let {
    heading,
    date,
    description,
    imageFluid,
    imageUrl,
    imageAlt
  } = _ref,
      props = _objectWithoutProperties(_ref, ["heading", "date", "description", "imageFluid", "imageUrl", "imageAlt"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "gray.90",
    pyScale: 0
  }, props), React.createElement(Flex, {
    flexDirection: ['column', null, 'row']
  }, React.createElement(Box, {
    width: [1, null, 1 / 2],
    mr: "auto",
    pyScale: ['m', null, 'xl']
  }, React.createElement(BackButton, {
    href: "/news/",
    mbScale: "s"
  }, "Back to News"), React.createElement(Text, {
    as: "p",
    mbScale: "s",
    fontSizeScale: "b",
    fontWeight: "semibold",
    color: "teal.70"
  }, date), React.createElement(Heading, {
    as: "h3",
    mbScale: "s"
  }, heading), React.createElement(Text, {
    prScale: [0, null, null, 'xxl'],
    fontFamily: "serif",
    fontSizeScale: "m",
    lineHeight: "copy"
  }, description)), (imageUrl || imageFluid) && React.createElement(ImageContainer, {
    width: [null, null, 'calc((100vw - 100%) / 2 + 4 / 6 * 100%)'],
    mr: [negateScale(theme.spaceScales.l)[0], 'calc(-1 * (100vw - 100%) / 2)'],
    mtScale: ['t', null, 0],
    mlScale: ['-l', null, 'xxl'],
    bg: "gray.20"
  }, React.createElement(AspectRatio, {
    x: 16,
    y: 8,
    minHeight: "100%"
  }, React.createElement(Image, {
    src: imageUrl,
    fluid: imageFluid,
    alt: imageAlt
  })))));
};

PageBodyNewsPostHeader.mapDataToProps = ({
  previousType,
  meta
}) => {
  var _page$data, _page$data$title, _newsMetadata$primary, _newsMetadata$primary2, _newsMetadata$primary3, _newsMetadata$primary4, _newsMetadata$primary5, _newsMetadata$primary6, _newsMetadata$primary7, _newsMetadata$primary8;

  const rootData = meta === null || meta === void 0 ? void 0 : meta.rootData;
  const page = rootData === null || rootData === void 0 ? void 0 : rootData.prismicPage;
  const newsMetadata = getMetadata('PrismicPageMainNews', page);
  return {
    previousIsHeader: previousType === 'PageBodyHeader',
    heading: page === null || page === void 0 ? void 0 : (_page$data = page.data) === null || _page$data === void 0 ? void 0 : (_page$data$title = _page$data.title) === null || _page$data$title === void 0 ? void 0 : _page$data$title.text,
    date: newsMetadata === null || newsMetadata === void 0 ? void 0 : (_newsMetadata$primary = newsMetadata.primary) === null || _newsMetadata$primary === void 0 ? void 0 : _newsMetadata$primary.date,
    description: newsMetadata === null || newsMetadata === void 0 ? void 0 : (_newsMetadata$primary2 = newsMetadata.primary) === null || _newsMetadata$primary2 === void 0 ? void 0 : _newsMetadata$primary2.description,
    imageFluid: newsMetadata === null || newsMetadata === void 0 ? void 0 : (_newsMetadata$primary3 = newsMetadata.primary) === null || _newsMetadata$primary3 === void 0 ? void 0 : (_newsMetadata$primary4 = _newsMetadata$primary3.featured_image) === null || _newsMetadata$primary4 === void 0 ? void 0 : _newsMetadata$primary4.fluid,
    imageUrl: newsMetadata === null || newsMetadata === void 0 ? void 0 : (_newsMetadata$primary5 = newsMetadata.primary) === null || _newsMetadata$primary5 === void 0 ? void 0 : (_newsMetadata$primary6 = _newsMetadata$primary5.featured_image) === null || _newsMetadata$primary6 === void 0 ? void 0 : _newsMetadata$primary6.url,
    imageAlt: notEmpty(newsMetadata === null || newsMetadata === void 0 ? void 0 : (_newsMetadata$primary7 = newsMetadata.primary) === null || _newsMetadata$primary7 === void 0 ? void 0 : (_newsMetadata$primary8 = _newsMetadata$primary7.featured_image) === null || _newsMetadata$primary8 === void 0 ? void 0 : _newsMetadata$primary8.alt)
  };
};

PageBodyNewsPostHeader.mapDataToContext = () => ({
  backgroundColor: 'white',
  headerLogoColor: 'white',
  headerBg: 'teal.75'
});