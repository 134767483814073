import _styled from "styled-components";

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { propPairsEq } from 'src/helpers';
import { Grid, Box, Text } from 'system';
import { Anchor } from 'src/components/Anchor';
import { Subheading } from 'src/components/Subheading';
import { Heading } from 'src/components/Heading';
import { BoundedBox } from 'src/components/BoundedBox'; //TODO hook up to prismic via static queries

var _StyledAnchor = _styled(Anchor).withConfig({
  displayName: "PageBodyContactInfo___StyledAnchor",
  componentId: "sc-1x95jqv-0"
})(["&:hover,&:focus{color:", ";}"], p => p._css);

export const PageBodyContactInfo = _ref => {
  let props = _extends({}, _ref);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    maxWidth: "large"
  }, props), React.createElement(Heading, {
    as: "h3",
    fontSizeScale: "xl",
    mbScale: "s",
    mt: 3,
    boxStyle: "firstLastNoMargin"
  }, "Get in touch"), React.createElement(Text, {
    as: "p",
    mbScale: "m",
    fontSizeScale: "b"
  }, "Access to Shangri La is by reservation or business appointment only."), React.createElement(Text, {
    fontSizeScale: "b"
  }, React.createElement(Grid, {
    color: "gray.20",
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridRowGapScale: "s-"
  }, React.createElement(Text, {
    lineHeight: "wide",
    gridRow: ['1', 'span 2']
  }, React.createElement(Subheading, {
    as: "h4",
    color: "teal.40",
    mbScale: "t-"
  }, "Location"), React.createElement(Text, {
    as: "p",
    fontWeight: "semibold",
    mbScale: "t-"
  }, "Shangri La", React.createElement("br", null), "4055 Papu Circle", React.createElement("br", null), "Honolulu, HI 96816"), React.createElement(Text, {
    as: "p"
  }, React.createElement(_StyledAnchor, {
    href: "https://www.google.com/maps/place/Shangri+La+Museum+of+Islamic+Art,+Culture+%26+Design/@21.2569227,-157.7972345,17z/data=!3m1!4b1!4m5!3m4!1s0x7c00729249b2fff7:0x4cef1ec4203e887e!8m2!3d21.2569227!4d-157.7950458",
    target: "_blank",
    color: "orange.60",
    fontWeight: "bold",
    transitionProperty: "color",
    _css: themeGet('colors.teal.70')
  }, "View on Google Maps"))), React.createElement(Box, null, React.createElement(Subheading, {
    as: "h4",
    color: "teal.40",
    mbScale: "t-"
  }, "Phone"), React.createElement(Text, {
    as: "p",
    fontWeight: "semibold"
  }, "(808) 734-1941")), React.createElement(Box, null, React.createElement(Subheading, {
    as: "h4",
    color: "teal.40",
    mbScale: "t-"
  }, "Fax"), React.createElement(Text, {
    as: "p",
    fontWeight: "semibold"
  }, "(808) 732-4361")))));
};

PageBodyContactInfo.mapDataToProps = ({
  previousType,
  context,
  previousContext,
  nextContext
}) => ({
  previousIsHeader: previousType === 'PageBodyHeader',
  previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
  nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
  nextSharesBackground: propPairsEq('backgroundColor', context, nextContext)
});

PageBodyContactInfo.mapDataToContext = () => ({
  headerLogoColor: 'white',
  headerBg: 'teal.75',
  backgroundColor: 'white'
});