import _styled from "styled-components";
import React from 'react';
import { motion } from 'framer-motion';
import { getRichText } from 'helpers';
import { useSettingsData } from 'src/hooks/useSettingsData';
import { HEADER_HEIGHT_SCALE } from 'src/constants';
import { Box, Flex, Grid, Text } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Button } from 'src/components/Button';
import { Heading } from 'src/components/Heading';
import { HTMLContent } from 'src/components/HTMLContent';
import { Anchor } from 'src/components/Anchor';
import { useNavContext } from './NavContext';
import { MobileNav } from './MobileNav';
const transition = {
  type: 'spring',
  damping: 30,
  stiffness: 200
};

var _StyledBoundedBox = _styled(BoundedBox).withConfig({
  displayName: "DesktopNav___StyledBoundedBox",
  componentId: "sc-91h5vw-0"
})(["-webkit-overflow-scrolling:touch;"]);

export const DesktopNav = () => {
  var _settingsData$tickets, _settingsData$tickets2;

  const {
    isMenuOpen
  } = useNavContext();
  const variant = isMenuOpen ? 'open' : 'closed';
  const variants = {
    open: {
      visibility: 'visible',
      pointerEvents: 'auto',
      transition: {
        staggerChildren: 0.15,
        when: 'beforeChildren'
      }
    },
    closed: {
      transition: {
        when: 'afterChildren',
        staggerChildren: 0.15,
        staggerDirection: -1
      },
      transitionEnd: {
        visibility: 'hidden',
        pointerEvents: 'none'
      }
    }
  };
  const panelVariants = {
    open: {
      width: '50%',
      transition
    },
    closed: {
      width: '0%',
      transition
    }
  };
  const panelContentVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition
    },
    closed: {
      opacity: 0,
      x: '20px',
      transition
    }
  };
  const settingsData = useSettingsData();
  const ticketsMessage = getRichText(settingsData.tickets_message);
  return React.createElement(Box, {
    as: motion.div,
    display: ['none', 'block'],
    position: "fixed",
    width: "100vw",
    height: "100vh",
    zIndex: "slidingMenu",
    initial: "closed",
    justifyContent: "flex-end",
    animate: variant,
    variants: variants
  }, React.createElement(Flex, {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "flex-end"
  }, React.createElement(Box, {
    as: motion.div,
    bg: "teal.75",
    variants: panelVariants
  }), React.createElement(Box, {
    as: motion.div,
    bg: "teal.70",
    variants: panelVariants
  })), React.createElement(_StyledBoundedBox, {
    height: "100%",
    py: HEADER_HEIGHT_SCALE,
    pxScale: "0",
    overflow: "hidden scroll"
  }, React.createElement(Grid, {
    alignContent: "center",
    alignItems: "baseline",
    gridTemplateColumns: "repeat(2, 1fr)",
    minHeight: "100%",
    position: "relative"
  }, React.createElement(BoundedBox, {
    pyScale: "0",
    flex: "1 1 0px"
  }, React.createElement(Flex, {
    as: motion.div,
    variants: panelContentVariants,
    justifyContent: "center"
  }, React.createElement(Box, null, React.createElement(HTMLContent, {
    html: ticketsMessage,
    map: {
      h1: props => React.createElement(Heading, Object.assign({
        color: "white",
        fontSizeScale: "xl",
        mbScale: "t",
        lineHeight: "solid"
      }, props)),
      p: props => React.createElement(Text, Object.assign({
        as: "p",
        mbScale: "s",
        boxStyle: "lastNoMargin",
        maxWidth: "35rem"
      }, props))
    },
    fontSizeScale: "m",
    lineHeight: "wide",
    mbScale: "s"
  }), React.createElement(Button, {
    as: Anchor,
    href: settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$tickets = settingsData.tickets_link) === null || _settingsData$tickets === void 0 ? void 0 : _settingsData$tickets.url,
    target: settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$tickets2 = settingsData.tickets_link) === null || _settingsData$tickets2 === void 0 ? void 0 : _settingsData$tickets2.target,
    colorScheme: "yellowFill",
    alignSelf: "flex-start"
  }, "Tickets")))), React.createElement(BoundedBox, {
    as: motion.div,
    pyScale: "0",
    flex: "1 1 0px",
    variants: panelContentVariants
  }, React.createElement(MobileNav, {
    delay: 0.25,
    pt: 0,
    width: "100%",
    position: "static",
    bg: "transparent",
    withButton: false,
    display: "flex",
    justifyContent: "center",
    height: "auto",
    maxHeight: "auto",
    withSlide: false,
    mbScale: "-xxl"
  })))));
};