import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import { themeGet } from '@styled-system/theme-get';
import { map, isEmpty, chunk } from 'tiny-compose-fns';
import { propPairsEq } from 'src/helpers';
import { usePaginationWithUrlParams } from 'src/hooks/usePagination';
import { Box, Grid, Text, Link } from 'system';
import { BoundedBox } from 'src/components/BoundedBox';
import { Heading } from 'src/components/Heading';
import { Subheading } from 'src/components/Subheading';
import { Pagination } from 'src/components/Pagination';
import { PageBodySmallExperienceCards } from 'src/slices/PageBodySmallExperienceCards';
const PAGES_PER_PAGE = 6;

const fetchJson = async url => {
  const req = await fetch(url);
  const data = await req.text();
  return data;
};

var _StyledLink = _styled(Link).withConfig({
  displayName: "PageBodySearchResults___StyledLink",
  componentId: "sc-1dz4mvw-0"
})(["outline:0;&:hover,&:focus{color:", ";}"], p => p._css);

var _StyledText = _styled(Text).withConfig({
  displayName: "PageBodySearchResults___StyledText",
  componentId: "sc-1dz4mvw-1"
})(["-webkit-box-orient:vertical;-webkit-line-clamp:3;"]);

const PageSearchResult = _ref => {
  let {
    type,
    title,
    excerpt,
    href
  } = _ref,
      props = _objectWithoutProperties(_ref, ["type", "title", "excerpt", "href"]); // Hardcoded fixes to convert multi-word types to titles.


  const typeMap = {
    Careeropening: 'Career Opening'
  };
  return React.createElement(Grid, Object.assign({
    gridTemplateColumns: ['1fr', '1fr 3fr'],
    gridGapScale: ['t', 's']
  }, props), React.createElement(Subheading, {
    as: "p",
    mtScale: "t-",
    lineHeight: "copy"
  }, typeMap[type] || type), React.createElement(Box, null, React.createElement(Heading, {
    as: "p",
    mbScale: "t",
    boxStyle: "lastNoMargin"
  }, React.createElement(_StyledLink, {
    href: href,
    transitionProperty: "color",
    _css: themeGet('colors.teal.70')
  }, title)), excerpt && React.createElement(_StyledText, {
    as: "p",
    display: "-webkit-box",
    fontSizeScale: "b",
    lineHeight: "copy",
    overflow: "hidden"
  }, excerpt)));
};

var _StyledLink2 = _styled(Link).withConfig({
  displayName: "PageBodySearchResults___StyledLink2",
  componentId: "sc-1dz4mvw-2"
})(["&:hover,&:focus{color:", ";}"], p => p._css2);

var _StyledBox = _styled(Box).withConfig({
  displayName: "PageBodySearchResults___StyledBox",
  componentId: "sc-1dz4mvw-3"
})(["&:first-child{border-top:0;}"]);

export const PageBodySearchResults = _ref2 => {
  var _state$localSearchExp, _state$localSearchExp2, _state$localSearchPag, _state$localSearchPag2;

  let {
    query
  } = _ref2,
      props = _objectWithoutProperties(_ref2, ["query"]);

  const pagesRef = useRef();
  const [pagesPage, setPagesPage] = usePaginationWithUrlParams(0, pagesRef);
  const [state, setState] = useState({
    localSearchExperiences: {
      index: null,
      store: null
    },
    localSearchPages: {
      index: null,
      store: null
    }
  });
  const experiencesResults = useFlexSearch(query, state === null || state === void 0 ? void 0 : (_state$localSearchExp = state.localSearchExperiences) === null || _state$localSearchExp === void 0 ? void 0 : _state$localSearchExp.index, state === null || state === void 0 ? void 0 : (_state$localSearchExp2 = state.localSearchExperiences) === null || _state$localSearchExp2 === void 0 ? void 0 : _state$localSearchExp2.store);
  const pagesResults = useFlexSearch(query, state === null || state === void 0 ? void 0 : (_state$localSearchPag = state.localSearchPages) === null || _state$localSearchPag === void 0 ? void 0 : _state$localSearchPag.index, state === null || state === void 0 ? void 0 : (_state$localSearchPag2 = state.localSearchPages) === null || _state$localSearchPag2 === void 0 ? void 0 : _state$localSearchPag2.store);
  const pagedPagesResults = useMemo(() => chunk(PAGES_PER_PAGE, pagesResults), [pagesResults]);
  useEffect(() => {
    const url = '/search-data/';

    const asyncEffect = async () => {
      const urls = [url + "/experiences-store.json", url + "/experiences-index.json", url + "/pages-store.json", url + "/pages-index.json"];
      const [expStore, expIndex, pageStore, pageIndex] = await Promise.all(urls.map(fetchJson));
      setState({
        localSearchExperiences: {
          store: JSON.parse(expStore),
          index: expIndex
        },
        localSearchPages: {
          store: JSON.parse(pageStore),
          index: pageIndex
        }
      });
    };

    asyncEffect();
  }, []);
  const totalCount = experiencesResults.length + pagesResults.length;
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "white",
    minHeight: "50vh"
  }, props), query && React.createElement(Subheading, {
    as: "p",
    color: "teal.40",
    mbScale: "l",
    mtScale: "-m"
  }, "Showing ", totalCount, " result", totalCount !== 1 && 's', " for", ' ', React.createElement(Text, {
    as: "strong",
    fontWeight: "bold",
    display: "inline"
  }, query)), !query && React.createElement(React.Fragment, null, React.createElement(Heading, {
    mbScale: "t"
  }, "Enter a search term above."), React.createElement(Text, {
    as: "p",
    fontSizeScale: "b"
  }, "Results will appear here.")), query && totalCount < 1 && React.createElement(React.Fragment, null, React.createElement(Heading, {
    mbScale: "t"
  }, "Sorry, no results matched your search terms."), React.createElement(Text, {
    as: "p",
    fontSizeScale: "b"
  }, "Try a different query or", ' ', React.createElement(_StyledLink2, {
    href: "/",
    color: "orange.60",
    transitionProperty: "color",
    _css2: themeGet('colors.teal.70')
  }, "go to the home page"), ".")), !isEmpty(experiencesResults) && React.createElement(PageBodySmallExperienceCards, {
    pxScale: 0,
    pyScale: 0,
    maxWidth: "none",
    mbScale: [0, 'm']
  }, map(experience => React.createElement(PageBodySmallExperienceCards.ExperienceCard, {
    key: experience === null || experience === void 0 ? void 0 : experience.uid,
    href: experience === null || experience === void 0 ? void 0 : experience.url,
    type: experience === null || experience === void 0 ? void 0 : experience.type,
    title: experience === null || experience === void 0 ? void 0 : experience.title,
    description: experience === null || experience === void 0 ? void 0 : experience.description,
    dates: experience === null || experience === void 0 ? void 0 : experience.dates,
    imageFluid: experience === null || experience === void 0 ? void 0 : experience.imageFluid,
    imageAlt: experience === null || experience === void 0 ? void 0 : experience.imageAlt
  }), experiencesResults)), !isEmpty(pagesResults) && React.createElement(Box, {
    ref: pagesRef
  }, React.createElement(Box, {
    as: "ul",
    width: [1, 4 / 6],
    borderTop: [null, '2px solid'],
    borderColor: [null, 'gray.90']
  }, map(page => React.createElement(_StyledBox, {
    as: "li",
    key: page === null || page === void 0 ? void 0 : page.uid,
    pyScale: "m",
    borderTop: ['1px solid', '2px solid'],
    borderColor: ['gray.90', 'gray.90']
  }, React.createElement(PageSearchResult, {
    type: page === null || page === void 0 ? void 0 : page.type,
    title: page === null || page === void 0 ? void 0 : page.title,
    href: page === null || page === void 0 ? void 0 : page.url,
    excerpt: page === null || page === void 0 ? void 0 : page.excerpt
  })), pagedPagesResults[pagesPage])), React.createElement(Pagination, {
    total: pagedPagesResults === null || pagedPagesResults === void 0 ? void 0 : pagedPagesResults.length,
    current: pagesPage,
    handleSet: setPagesPage
  })));
};

PageBodySearchResults.mapDataToProps = ({
  data,
  previousType,
  context,
  previousContext,
  nextContext
}) => ({
  previousIsHeader: previousType === 'PageBodyHeader',
  previousOverhangs: previousContext === null || previousContext === void 0 ? void 0 : previousContext.overhangsNext,
  nextOverhangs: nextContext === null || nextContext === void 0 ? void 0 : nextContext.overhangsPrevious,
  nextSharesBackground: propPairsEq('backgroundColor', context, nextContext),
  query: data === null || data === void 0 ? void 0 : data.query
});

PageBodySearchResults.mapDataToContext = () => ({
  backgroundColor: 'white',
  headerLogoColor: 'white',
  headerBg: 'teal.75'
});